import { Slot } from "@radix-ui/react-slot";
import { type VariantProps, cva } from "class-variance-authority";
import * as React from "react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "./alert-dialog";

import { cn } from "app/lib/utils";

const buttonVariants = cva(
  "inline-flex items-center justify-center whitespace-nowrap rounded-md text-base font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        default:
          "bg-primary font-bold text-primary-foreground hover:bg-primary/90",
        destructive:
          "bg-destructive font-bold text-destructive-foreground hover:bg-destructive/90",
        outline:
          "border bg-background font-bold hover:bg-accent hover:text-accent-foreground",
        secondary:
          "bg-secondary font-bold text-secondary-foreground shadow-button hover:bg-secondary/80",
        ghost: "font-bold hover:bg-accent hover:text-accent-foreground",
        link: "font-bold text-primary underline-offset-4 hover:underline",
      },
      size: {
        default: "h-12 px-4 py-2",
        sm: "h-9 rounded-md px-3 text-sm",
        lg: "h-11 rounded-md px-8",
        icon: "h-12 w-12",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        data-variant={variant || "default"}
        ref={ref}
        {...props}
      />
    );
  },
);
Button.displayName = "Button";

export function AlertDialogButton({
  title,
  description,
  action = "OK",
  cancel = "キャンセル",
  asChild = false,
  onSuccess,
  children,
  variant,
  ...props
}: {
  title: React.ReactNode;
  description: React.ReactNode;
  action?: React.ReactNode;
  cancel?: React.ReactNode;
  asChild?: boolean;
  onSuccess: () => void;
  children: React.ReactNode;
} & ButtonProps) {
  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        {asChild ? (
          children
        ) : (
          <Button variant={variant} {...props}>
            {children}
          </Button>
        )}
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{title}</AlertDialogTitle>
          <AlertDialogDescription asChild>{description}</AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>{cancel}</AlertDialogCancel>
          <AlertDialogAction variant={variant} onClick={() => onSuccess()}>
            {action}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

export { Button, buttonVariants };
