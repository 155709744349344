import type { LoaderFunctionArgs } from "@remix-run/node";
import {
  isRouteErrorResponse,
  Links,
  Meta,
  Outlet,
  redirect,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useLocation,
  useRevalidator,
  useRouteError,
  useRouteLoaderData,
} from "@remix-run/react";
import { captureRemixErrorBoundaryError } from "@sentry/remix";
import "photoswipe/dist/photoswipe.css";
import { useEffect } from "react";
import ErrorFeedback from "./components/parts/errorFeedback";
import "./globals.css";
import {
  NativeAppProvider,
  useAppFocus,
  useHandleActivityOpen,
} from "./hooks/nativeApp";
import { UserProvider } from "./hooks/user";
import { cn } from "./lib/utils";
import { getUnreadCount } from "./models/.server/activities";
import { getUserById } from "./models/.server/users";
import { authenticator, getUserId } from "./services/auth.server";

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const env = {
    APP_URL: process.env.APP_URL,
    GA4_MEASUREMENT_ID: process.env.GA4_MEASUREMENT_ID,
  };

  const { pathname, search } = new URL(request.url);

  // Redirect to the same URL without a trailing slash
  if (pathname.endsWith("/") && pathname !== "/") {
    return redirect(`${pathname.slice(0, -1)}${search}`, 301);
  }

  // User data
  const userId = await getUserId(request);

  if (!userId) {
    return { ...env, user: null };
  }

  const [user, unreadCount] = await Promise.all([
    getUserById(userId),
    getUnreadCount(userId),
  ]);

  if (!user) {
    return { ...env, user: null };
  }

  if (user.deletedAt) {
    // Force logout
    return await authenticator.logout(request, { redirectTo: "/" });
  } else if (!user.emailVerified) {
    // Redirect to email confirmation page
    if (!pathname.startsWith("/auth/signup/email/confirm")) {
      return redirect("/auth/signup/email/confirm");
    }

    // Redirect to profile page
  } else if (user.name === "") {
    if (!pathname.startsWith("/auth/signup/profile")) {
      return redirect("/auth/signup/profile");
    }
  }

  return { ...env, user: { ...user, unreadCount } };
};

export function Layout({ children }: { children: React.ReactNode }) {
  const data = useRouteLoaderData<{
    APP_URL: string;
    GA4_MEASUREMENT_ID: string | undefined;
  }>("root");
  const { APP_URL, GA4_MEASUREMENT_ID } = data ?? {};
  const { pathname, search } = useLocation();

  return (
    <html lang="ja">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, user-scalable=no" />
        <meta property="og:type" content="article" />
        <meta property="og:site_name" content="マイコミュ" />
        {APP_URL && (
          <>
            <meta
              property="og:url"
              content={`${APP_URL}${pathname}${search ? search : ""}`}
            />
            <meta property="og:image" content={`${APP_URL}/og-image.jpg`} />
          </>
        )}
        <Meta />
        <Links />
      </head>
      <body>
        {children}
        <ScrollRestoration />
        <Scripts />

        {GA4_MEASUREMENT_ID && (
          <>
            <script
              async
              src={`https://www.googletagmanager.com/gtag/js?id=${GA4_MEASUREMENT_ID}`}
            ></script>
            <script
              dangerouslySetInnerHTML={{
                __html: `window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());
gtag('config', '${GA4_MEASUREMENT_ID}');`,
              }}
            ></script>
          </>
        )}
      </body>
    </html>
  );
}

export const ErrorBoundary = () => {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);

  if (isRouteErrorResponse(error)) {
    const statusText =
      typeof error.data === "string" ? error.data : error.statusText;
    return <ErrorFeedback status={error.status} statusText={statusText} />;

    // Show error details in development
  } else if (process.env.NODE_ENV === "development" && error instanceof Error) {
    return (
      <div className="m-4">
        <h1 className="text-3xl font-bold text-attention">Error</h1>
        <p className="mt-2 text-lg font-bold">{error.message}</p>
        <pre className="mt-4 overflow-x-scroll border bg-gray-1 p-2 text-xs">
          {error.stack}
        </pre>
      </div>
    );
  }

  return <ErrorFeedback status={500} />;
};

export default function App() {
  const { user } = useLoaderData<typeof loader>();

  // Revalidate when the app is focused
  const revalidator = useRevalidator();
  const focused = useAppFocus();

  useEffect(() => {
    if (focused) {
      revalidator.revalidate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focused]);

  // Handle activity open
  useHandleActivityOpen();

  // Layout
  const location = useLocation();
  const isAdmin = /^\/admin/.test(location.pathname);

  return (
    <NativeAppProvider>
      <UserProvider user={user}>
        <div
          className={cn(!isAdmin && "max-container flex min-h-svh flex-col")}
        >
          <Outlet />
        </div>
      </UserProvider>
    </NativeAppProvider>
  );
}
